export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyBLk2bdC3waMeuHGM6AuUJRCKV5LPwpbww',
    authDomain: 'analog-pics-a1a3b.firebaseapp.com',
    databaseURL: 'https://analog-pics-a1a3b.firebaseio.com',
    projectId: 'analog-pics-a1a3b',
    storageBucket: 'analog-pics-a1a3b.appspot.com',
    messagingSenderId: '851287805520',
  },
};
